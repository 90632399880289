import React from 'react';
import { TextStyle as S } from './Text.style';
import { fontSize, fontWeight, lineHeight, colourPalette } from 'styles/styles';

export type TextProps = {
  size?: keyof typeof fontSize;
  weight?: keyof typeof fontWeight;
  lineHeight?: keyof typeof lineHeight;
  as?: 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'small';
  align?: 'left' | 'right' | 'center';
  color?: keyof typeof colourPalette;
  transform?: 'capitalize' | 'lowercase' | 'uppercase' | 'upper-first-letter';
  ellipsis?: boolean;
  fit?: 'content' | 'space';
  className?: string;
  children?: React.ReactNode;
};

export const Text: React.FC<TextProps> = ({ className, children, as, ...props }) => {
  return (
    <S.Component as={as} $props={props} className={className}>
      {children}
    </S.Component>
  );
};
