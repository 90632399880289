import React, { useMemo } from 'react';
import { Toast, ToastMessage, ToastProps } from 'primereact/toast';
import { SnackbarStyle as S } from './Snackbar.style';
import { ReactComponent as InfoIcon } from 'assets/svg/information-filled.svg';
import { ReactComponent as SuccessIcon } from 'assets/svg/checkmark-filled.svg';
import { ReactComponent as ErrorIcon } from 'assets/svg/error-filled.svg';
import { ReactComponent as WarningIcon } from 'assets/svg/warning-filled.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { PButton } from 'components/prime/PButton/PButton';
import { PButtonIcon } from 'components/prime/PButton/PButtonIcon';

/**
 * @deprecated Use SnackbarV2 instead
 */
export type SnackbarMessageCustomProps = {
  severity?: ToastMessage['severity'];
  title?: string | React.ReactNode;
  label: string | React.ReactNode;
  closable?: boolean;
  action?: {
    label: string;
    onClick?: () => void;
  };
  sticky?: boolean;
  onDismiss: () => void;
};

/**
 * @deprecated Use SnackbarV2 instead
 */
export const SnackbarMessageCustom: React.FC<SnackbarMessageCustomProps> = ({
  label,
  title,
  action,
  closable,
  severity,
  sticky,
  onDismiss,
}) => {
  const icon = useMemo(() => {
    switch (severity) {
      case 'info':
        return <InfoIcon className="icon-info" />;
      case 'success':
        return <SuccessIcon className="icon-success" />;
      case 'error':
        return <ErrorIcon className="icon-error" />;
      case 'warn':
        return <WarningIcon className="icon-warning" />;
      default:
        return null;
    }
  }, [severity]);
  const actionComponent = useMemo(() => {
    return (
      <S.Actions>
        {!!action && (
          <PButton
            {...action}
            onClick={() => {
              action.onClick?.();
              onDismiss();
            }}
            size="sm"
            severity="invert"
          />
        )}
        {(sticky || closable) && <PButtonIcon onClick={onDismiss} icon={<CloseIcon />} size="xs" />}
      </S.Actions>
    );
  }, [sticky, closable, action, onDismiss]);

  return (
    <S.SnackbarMessage>
      {!!icon && <div className="snackbar-message-custom-icon">{icon}</div>}
      <div className="snackbar-message-custom-content">
        {title && <div className="title">{title}</div>}
        <div className="label">{label}</div>
      </div>
      {!!actionComponent && <div className="snackbar-message-custom-actions">{actionComponent}</div>}
    </S.SnackbarMessage>
  );
};

/**
 * @deprecated Use SnackbarV2 instead
 */
export const Snackbar = React.forwardRef<Toast, ToastProps>((_, ref) => {
  return (
    <S.Snackbar
      appendTo={window.document.body}
      transitionOptions={{ classNames: 'snackbar-anim' } as any}
      position="bottom-center"
      ref={ref}
    />
  );
});
